import styled from "@emotion/styled"

const Promo = styled("h2")`
font-family: "Bebas Neue", Arial;
color: white;
text-align: center;
font-size: 50px;
margin: 0;
width: 100%;
letter-spacing: 1px;
font-weight: 100;
`

export default Promo