import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Store = styled("li")`
  width: 200px;
  display: flex;
  margin: 5px 0;
  opacity: 1;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  img {
    width: 100%;
  }
`

const StoreList = styled("ul")`
  list-style: none;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
`

const Stores = ({ links }) => (
  <StoreList>
    {links.map((link, index) => {
      if(link.enabled){
        return (
          <Store key={index}>
            <a href={link.link.url} target="_blank" without rel="noreferrer">
              <img src={link.image.url} alt={link.image.alt} />
            </a>
          </Store>
        )
      } else{
        return null;
      }
    })}
  </StoreList>
)

export default Stores

Stores.propTypes = {
  links: PropTypes.array.isRequired,
}
